import React from 'react';
import styled from 'styled-components';

const PrivacyPolicyWrapper = styled.div`
  padding: 40px 0;
  position: relative;
`;

const BackButton = styled.button`
  position: absolute;
  top: 20px;
  left: 20px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: #007bff;
  display: flex;
  align-items: center;
  
  &:before {
    content: '←';
    margin-right: 5px;
    font-size: 20px;
  }
`;

const Container = styled.div`
  margin: 0 30%;
  padding: 0 20px;
`;
const Heading = styled.h1`
  font-size: 36px;
  text-align: center;
  margin-bottom: 20px;
`;

const Intro = styled.p`
  text-align: center;
  font-size: 18px;
  margin-bottom: 20px;
`;

const DownloadLink = styled.a`
  display: block;
  text-align: center;
  margin-bottom: 40px;
  font-size: 20px;
  color: #007bff;
  text-decoration: none;
`;

const Section = styled.div`
  margin-bottom: 40px;
  /* background-color: #fff; */
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const SectionHeading = styled.h2`
  font-size: 24px;
  margin-bottom: 20px;
`;

const List = styled.ul`
  margin-left: 20px;
`;

const ListItem = styled.li`
  margin-bottom: 10px;
`;

const OrderedList = styled.ol`
  margin-left: 20px;
`;

const Link = styled.a`
  /* color: #007bff; */
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const PrivacyPolicy = () => {
  const handleBackClick = () => {
    window.history.back();
  };
  return (
    <PrivacyPolicyWrapper>
      <BackButton onClick={handleBackClick}>Back</BackButton>

      <Container>
        <Heading>Privacy Policy</Heading>
        <Intro>
          I have built two extensions that you can find on the Chrome Web Store. Google is making me write the privacy policies, so here they are.
        </Intro>
        <DownloadLink href="#fast-status-bar">TimeLine Chrome Extension</DownloadLink>
        <DownloadLink href="#fable-access">FABLE Access Chrome Extension</DownloadLink>
        <Section id="fast-status-bar">
          <SectionHeading>TimeLine Chrome Extension</SectionHeading>
          <DownloadLink href="https://chrome.google.com/webstore/detail/fast-status-bar/your-extension-id">Download it here!</DownloadLink>

          <Section>
            <SectionHeading>Information Collection</SectionHeading>
            <List>
              <ListItem>
                <strong>Web Page Content:</strong> The TimeLine Chrome Extension accesses the content of the web pages you visit to insert the status bar element and apply the necessary styling.
              </ListItem>
              <ListItem>
                <strong>Google Calendar and Tasks:</strong> With your explicit permission, the extension communicates with your Google Calendar and Google Tasks accounts to fetch and display your events and tasks within the status bar.
              </ListItem>
              <ListItem>
                <strong>Local Storage:</strong> TimeLine stores certain settings and preferences, such as the position of the status bar, using your browser's local storage. This data is stored locally on your device and is not transmitted to any external servers.
              </ListItem>
            </List>
          </Section>

          <Section>
            <SectionHeading>Use of Collected Data</SectionHeading>
            <OrderedList>
              <ListItem>
                <em>Status Bar Integration:</em> The extension uses the accessed web page content to seamlessly integrate the status bar into the webpage, ensuring a consistent and non-intrusive user experience.
              </ListItem>
              <ListItem>
                <em>Event and Task Display:</em> TimeLine retrieves your calendar events and tasks from your Google accounts to display them visually within the status bar. This allows you to have a quick overview of your schedule and pending tasks while browsing the web.
              </ListItem>
              <ListItem>
                <em>User Preferences:</em> The extension stores your preferences, such as the position of the status bar, locally in your browser's storage. This enables the extension to remember your settings and provide a personalized experience across different web pages.
              </ListItem>
            </OrderedList>
          </Section>

          <Section>
            <SectionHeading>Data Sharing and Disclosure</SectionHeading>
            <List>
              <ListItem>
                <strong>No Third-Party Sharing:</strong> TimeLine does not share, sell, or disclose any of the collected data to third parties. All data processing occurs locally on your device, and no information is transmitted to external servers or entities.
              </ListItem>
              <ListItem>
                <strong>Google APIs:</strong> The extension communicates with Google's servers to fetch your calendar events and tasks using the provided OAuth 2.0 authentication. However, the extension only accesses the minimum required data necessary to display the information within the status bar. No personal data is stored or transmitted by the extension itself.
              </ListItem>
              <ListItem>
                <strong>Compliance with Laws:</strong> In exceptional circumstances, we may disclose your information if required by law, subpoena, or court order, or if we believe in good faith that such disclosure is necessary to protect our rights, your safety, or the safety of others.
              </ListItem>
            </List>
          </Section>

          <Section>
            <SectionHeading>Data Security</SectionHeading>
            <List>
              <ListItem>
                <strong>Secure Communication:</strong> TimeLine uses secure communication protocols, such as HTTPS, to encrypt data transmitted between your browser and Google's servers, ensuring the confidentiality and integrity of your information.
              </ListItem>
              <ListItem>
                <strong>OAuth 2.0 Authentication:</strong> The extension follows the OAuth 2.0 protocol to securely authenticate and authorize access to your Google Calendar and Tasks data. This ensures that your credentials are never stored or accessed by the extension itself.
              </ListItem>
              <ListItem>
                <strong>Local Storage:</strong> The data stored locally on your device, such as extension preferences, is protected by the security measures implemented by your browser and the operating system.
              </ListItem>
            </List>
          </Section>

          <Section>
            <SectionHeading>User Control and Opt-Out</SectionHeading>
            <List>
              <ListItem>
                <strong>Permission Management:</strong> You have full control over the permissions granted to the TimeLine Chrome Extension. You can manage these permissions through your browser's extension settings, allowing you to restrict or revoke access to certain features, such as Google Calendar and Tasks integration, if desired.
              </ListItem>
              <ListItem>
                <strong>Opt-Out:</strong> If you no longer wish to use the TimeLine Chrome Extension, you can easily disable or uninstall it from your browser. Upon uninstallation, all locally stored data associated with the extension will be removed from your device.
              </ListItem>
            </List>
          </Section>

          <Section>
            <SectionHeading>Children's Privacy</SectionHeading>
            <p>
              TimeLine is not intended for use by individuals under the age of 13. We do not knowingly collect personal information from children. If we become aware that a child under 13 has provided us with personal information, we will take steps to delete such information.
            </p>
          </Section>

          <Section>
            <SectionHeading>Updates to the Privacy Policy</SectionHeading>
            <p>
              We reserve the right to update or modify this Privacy Policy at any time. Any changes will be effective immediately upon posting the revised policy. We encourage you to review this Privacy Policy periodically to stay informed about how we collect, use, and protect your information.
            </p>
          </Section>

          <Section>
            <SectionHeading>Contact</SectionHeading>
            <p>
              If you have any questions, concerns, or requests regarding this Privacy Policy or our data practices, please contact me at: <Link href="mailto:tfenner@usc.edu">tfenner@usc.edu</Link>
            </p>
            <p>By using the TimeLine Chrome Extension, you acknowledge that you have read, understood, and agree to be bound by this Privacy Policy.</p>
          </Section>
        </Section>
        <Section id="fable-access">
          <SectionHeading>FABLE Access Chrome Extension</SectionHeading>
          <DownloadLink href="https://chromewebstore.google.com/detail/fable-frontend/jiojlkokhkfijnmgljmoidoidkmlkedm">Download it here!</DownloadLink>

          <Section>
            <SectionHeading>Information Collection</SectionHeading>
            <List>
              <ListItem>
                <strong>Web Requests:</strong> The FABLE Access Chrome Extension monitors web requests made by your browser to detect and analyze potential issues, such as connection errors and redirects. This includes accessing the URLs, request headers, and response headers of the web pages you visit.
              </ListItem>
              <ListItem>
                <strong>Browser Tabs:</strong> The extension interacts with your browser tabs to send and receive messages, retrieve tab information, and execute content scripts within the context of web pages.
              </ListItem>
              <ListItem>
                <strong>Local Storage:</strong> FABLE Access stores and retrieves data, such as URL mappings, using your browser's local storage. This data is stored locally on your device and is not transmitted to any external servers.
              </ListItem>
              <ListItem>
                <strong>Active Tab:</strong> When you click on the extension icon, FABLE Access accesses information about the currently active tab, such as its URL, to provide relevant functionality and user interface elements.
              </ListItem>
            </List>
          </Section>

          <Section>
            <SectionHeading>Use of Collected Data</SectionHeading>
            <OrderedList>
              <ListItem>
                <em>Error Detection:</em> The collected web request data is used to identify connection errors, analyze potential issues, and provide timely alerts to enhance your browsing experience.
              </ListItem>
              <ListItem>
                <em>URL Transformation:</em> FABLE Access utilizes the collected URL data to perform advanced URL transformations locally on your device. These transformations aim to provide alternative or updated URLs when a webpage is not found or a link is broken.
              </ListItem>
              <ListItem>
                <em>Local Data Storage:</em> The extension stores URL mappings and other relevant data locally in your browser's storage to enable seamless functionality and improve performance. This data is not shared with any third parties.
              </ListItem>
              <ListItem>
                <em>Notifications:</em> FABLE Access may display notifications to inform you about important events, such as detected errors or the availability of transformed URLs. These notifications are generated locally and do not transmit any personal information.
              </ListItem>
            </OrderedList>
          </Section>

          <Section>
            <SectionHeading>Data Sharing and Disclosure</SectionHeading>
            <List>
              <ListItem>
                <strong>No Third-Party Sharing:</strong> FABLE Access does not share, sell, or disclose any of the collected data to third parties. All data processing occurs locally on your device, and no information is transmitted to external servers or entities.
              </ListItem>
              <ListItem>
                <strong>Backend Server:</strong> The extension communicates with a backend server hosted on Heroku to perform complex URL transformations and ensure scalability and reliability. However, the data sent to the server is limited to the necessary URL information required for the transformation process. No personally identifiable information is transmitted to the server.
              </ListItem>
              <ListItem>
                <strong>Compliance with Laws:</strong> In exceptional circumstances, we may disclose your information if required by law, subpoena, or court order, or if we believe in good faith that such disclosure is necessary to protect our rights, your safety, or the safety of others.
              </ListItem>
            </List>
          </Section>

          <Section>
            <SectionHeading>Data Security</SectionHeading>
            <List>
              <ListItem>
                <strong>Secure Communication:</strong> FABLE Access implements secure communication protocols, such as HTTPS, to encrypt data transmitted between your browser and the backend server, ensuring the confidentiality and integrity of the information.
              </ListItem>
              <ListItem>
                <strong>Local Storage:</strong> The data stored locally on your device, such as URL mappings, is protected by the security measures implemented by your browser and the operating system.
              </ListItem>
              <ListItem>
                <strong>Limited Data Collection:</strong> FABLE Access collects only the necessary data required for its functionality and does not gather any personally identifiable information unless explicitly provided by you.
              </ListItem>
            </List>
          </Section>

          <Section>
            <SectionHeading>User Control and Opt-Out</SectionHeading>
            <List>
              <ListItem>
                <strong>Permission Management:</strong> You have control over the permissions granted to the FABLE Access Chrome Extension. You can manage these permissions through your browser's extension settings, allowing you to restrict or revoke access to certain features if desired.
              </ListItem>
              <ListItem>
                <strong>Opt-Out:</strong> If you no longer wish to use the FABLE Access Chrome Extension, you can easily disable or uninstall it from your browser. Upon uninstallation, all locally stored data associated with the extension will be removed from your device.
              </ListItem>
            </List>
          </Section>

          <Section>
            <SectionHeading>Children's Privacy</SectionHeading>
            <p>
              FABLE Access is not intended for use by individuals under the age of 13. We do not knowingly collect personal information from children. If we become aware that a child under 13 has provided us with personal information, we will take steps to delete such information.
            </p>
          </Section>

          <Section>
            <SectionHeading>Updates to the Privacy Policy</SectionHeading>
            <p>
              We reserve the right to update or modify this Privacy Policy at any time. Any changes will be effective immediately upon posting the revised policy. We encourage you to review this Privacy Policy periodically to stay informed about how we collect, use, and protect your information.
            </p>
          </Section>

          <Section>
            <SectionHeading>Contact</SectionHeading>
            <p>
              If you have any questions, concerns, or requests regarding this Privacy Policy or our data practices, please contact me at: <Link href="mailto:tfenner@usc.edu">tfenner@usc.edu</Link>
            </p>
            <p>By using the FABLE Access Chrome Extension, you acknowledge that you have read, understood, and agree to be bound by this Privacy Policy.</p>
          </Section>
        </Section>
      </Container>
    </PrivacyPolicyWrapper>
  );
};

export default PrivacyPolicy;