import React, { useState } from 'react';
import './Transcripts.css'; // We'll create this CSS file separately

const Transcripts = () => {
  const [selectedWriting, setSelectedWriting] = useState(null);

  const writings = [
    {
      id: 1,
      title: "Impact bonds for AGI",
      excerpt: "How multistakeholder governance creates more humanist autonomous agents",
      content: `[Transcript Open - 7:22:08PST]
      
      [Speaker: Toby Fenner - 7:22:08PST]

      Last week I watched a movie called Minority Report, which came out maybe 20 years ago. Tom Cruise is a detective who solves murders that haven't even occurred yet – sometimes the murderer hasn't even considered the idea of committing the crime. 
      This is because Tom Cruise's police team has people on it with the ability to see into the future. If they see that someone will commit a crime in the future, Tom Cruise arrests them immediately and sends them to prison – even if they haven’t done anything yet. That's the sci-fi premise of this movie that was released two decades ago, and while it's not real, the concept of predictive policing is.
      Predictive policing is in use in dozens of cities and is one of the many unethical applications of AI and machine learning that we are witnessing today – I’ll go into more detail in a minute. We are just beginning to see the consequences of all these AI implementations where people haven't thoroughly considered the implications, and I want to share with you how these implications affect my vision for the future.
      
      [Speaker: Toby Fenner - 7:23:23PST]

      Firstly, I hope to convince you that the most critical action we can take right now is to ensure that our AI aligns with our human values. There are other arguments for increasing computational speed or perhaps putting all development on hold, but hopefully I can persuade you that aligning AI with human values should be our top priority.
      Secondly, I am confident that the only way we can achieve this alignment is through market forces and profit incentives. People are doing things like asking others to sign petitions pledging not to engage in unethical practices with AI, but I argue that this is ineffective. 
      Some background – the crucial aspect for understanding AI, as far as this talk is concerned, is that it's a program making human-level decisions without being explicitly programmed like typical software. There is no step-by-step process for decision-making. It takes in a vast amount of data, processes it all at once, and then produces an answer, and it's not always clear how it arrived at that answer. 
      This ambiguity is why the question of when we should and should not use this technology is becoming increasingly complex. My vision for the future is one where we can pursue both profit and ethical AI simultaneously – a future where these two goals are not mutually exclusive. 
      
      [Speaker: Toby Fenner - 7:24:01PST]

      But first I should probably explain why predictive policing is kind of garbage, despite seeming like a good idea. If we gather data about a neighborhood, like where crimes have been committed in the past year and economic indicators, it seems reasonable to attempt to predict where a car might be broken into next. Police officers have been able to intuit this, so we could likely improve this process with AI, right?
      The problem here is with the police overstepping in terms of privacy and security in the pursuit of perfect optimization. In Chicago, they installed tiny microphones on every telephone pole, constantly recording. If the microphone detects a sound loud enough, it identifies it as a gunshot and immediately dispatches a police officer to investigate. These microphones are recording continuously, and the police have a little map in their lair displaying where to go. This is obviously a tremendous waste of resources, but it's always justified in the name of security.
      When the police arrive, they have just received a notification indicating that a crime will occur in 30 minutes and are instructed to be ready with their hand on their holster, metaphorically speaking. 
      Because the software developers are trying to optimize for quantifying success, like claiming to have predicted 500 crimes this quarter, they don't genuinely care whether a crime actually ends up taking place.
      The Viterbi Ethics Committee stated that using AI for predictive policing in this manner violates their code of ethics because it exacerbates all the existing problems we have concerning biases within the police force and related issues. 
      
      [Speaker: Toby Fenner - 7:26:34PST]

      The apparent solution would be to open up the box a little and examine what makes the AI work, allowing us to tweak it and collaborate on improvements. So what if we tried that? 
      An investigative reporter approached the LAPD after they implemented this system in LA in 2011 and declared his intention to file a Freedom of Information Act request as a citizen, demanding to see the AI tool that was instructing the police to come to his neighborhood and arrest people.
      The police chief refused, and when questioned about the reason, he stated that they couldn't comply because they didn't understand how it worked. They didn't know how it was arriving at these conclusions – it was just spitting them out and they complied. 
      Either the police are lying about not understanding how it works and deceiving the public – which is completely impossible because the police would never do something like that – or they genuinely don't know what's happening inside this black box, which might be even more concerning. 
      They are placing significant weight on these decisions in part because people have a tendency to anthropomorphize systems like ChatGPT. I find myself saying things like "Thank you" because I feel like I'm conversing with an incredibly intelligent individual. Consequently, when the police receive these messages, they assign much more importance to them than they normally would. This is an example of the dangers of AI for predictive policing. 
      
      [Speaker: Toby Fenner - 7:28:11PST]

      I should also touch on the defense industry. There is a company called Shield AI that is developing AI to fly fighter jets autonomously, without a human pilot inside. The aircraft maneuvers itself and can even shoot. 
      The frightening aspect of this is imagining yourself in a traditional position in the army, standing in front of someone you have been tasked to kill – I think making the decision to kill would be challenging and probably not my first choice. 
      But if I were sitting at home holding a controller, the barrier to taking a life would be much lower. Now imagine you barely even need to glance at your screen while the AI does its job autonomously – my kill count would be significantly higher. This is referred to as antiseptic warfare because there is no literal, physical blood on your hands. Given this, I believe there would be many more deaths if the situation were imbalanced in this way.
      There have been arguments on the other side, suggesting that if everyone on the battlefield were robots and no humans were involved, it might be the only ethical approach. The argument goes like this: 
      To make an ethical decision, you have to have free will, because it means deciding to do something that aligns with one's moral principles. 
      To have free will, you must be able to make a rational decision based on antecedent reasoning – considering something from the past and then providing a completely logical answer. 
      The problem is that robots can do this every single time because that's all they do — it's pure logic. Humans, on the other hand, cannot do this because we have emotions and our judgment becomes clouded. We commit war crimes. 
      This argument suggests that not only is filling the battlefield with robots the moral thing to do, but it's the only ethical option because only robots can be ethical, while humans cannot. But this might be a bit extreme, because it relies on the assumption that we can always distinguish between attackers and civilians, which we currently cannot do. This is a problem for the future, but it's an interesting consideration. These companies are choosing not to think about it and are simply developing the technology now, which is unsettling.
      
      [Speaker: Toby Fenner - 7:32:48PST]

      Another way in which these companies are forging ahead with AI without carefully considering the implications is in the hiring process. If a company wants to hire more people and has noticed that they love the employees they have hired over the past 10 years, they may want to hire more individuals with the same general attitude, even if they can't pinpoint exactly what it is about them. So they train a model to filter out the first 100 applicants and provide the top 10 candidates.
      Some companies were doing this, and one of them went through all the applications, automatically rejecting any candidate who attended a college with "women's" in the name, rendering them ineligible. Other machine learning models would look at factors like the applicant's name and decide not to consider them before they even reached the interview stage.
      During my recent experience with the CS recruiting process, I encountered online interviews where you don't even speak to a real person. They ask you to record your responses to the camera, and they measure your vocal intonation to determine if you're nervous or confident when speaking. They analyze your smile and assess whether you might be too uptight, potentially disqualifying you from being hired, all before you even talk to a person. 
      You can imagine the ways in which this can be used to discriminate against people without needing to provide a public explanation. They can simply state that it wasn't a close fit. They are within their rights to not even offer an explanation. 
      
      [Speaker: Toby Fenner - 7:34:45PST]

      This doesn't paint an optimistic picture of the future of AI. But I believe there is a way we can change this, and that is by aligning market incentives. I believe it is the only way. 
      As a Computer Science and Business Administration major, I find myself excited about the potential of AI from a CS perspective, wanting to make things ethical. But then the business side of me realizes that as it stands this will not make any money. Today, there’s pretty much zero chance. How come?
      Companies have a fiduciary duty to provide value to their shareholders. If you're the lone board member suggesting pushing back a project by a week to ensure it's ethical, you risk being sued by the company for keeping profits lower than they could potentially be. As a result, you're unlikely to speak up. The short-term takes precedence over the long-term.
      There is a hidden cost involved. The benefits are provided to you immediately upon releasing the product, but the costs — the downsides — are externalized to everyone in the world. You might consider that 20 years from now, life could be worse because of your machine’s implicit bias, but it's not something you can quantify right now – It's something you can freely ignore if you're a developer for these companies. 
      So, what if we found a way to solve it – to bake in pro-ethics incentives directly to the market?
      I’ll come over here and illustrate on the blackboard. 
      
      [Speaker: Toby Fenner - 7:36:22PST]

      This is the current situation. There are three parties involved: the government, investors, and companies developing AI. I’ll label these as G, I, and AI. We know that the government wants AI to be moral and ethical, avoiding things like job displacement and racism. The government asks people to adhere to these principles. But the two agents — investors and AI companies — are only optimizing their interactions between each other, along this arrow here. Investors provide funding to AI producers because they want a high return on their investment. The AI companies love receiving funding so they can create better AI and pay their employees. To be honest they don’t care at all about what the government says – why would they?
      So, here's my proposed solution. 
      We introduce a fourth entity, which can be a company or something similar, that acts as an intermediary between all these parties. I’ll draw this circle in the center here. This entity writes contracts for something called an ‘impact bond’, which is already used in our government on a limited scale. It's not actually a bond, but rather a results-based contract that involves all three parties. It's a contract for investors who are investing in something, but they only receive their return if the project they're investing in has a positive impact that is quantified and measured as a success.
      How can we make this work? We have the government – which I trust with every fiber of my being – establish different goals, like keeping unemployment low. The government sets these objectives, and then this third-party intermediary creates the contract and presents it to everyone. They might say, for example, that keeping unemployment below 5% constitutes success. The investors then assume all the risk, which is advantageous because the government and AI developers don't take on any risk, only the investors, which aligns with their expectations.
      The investors provide immediate cash to the AI developers, who are super stoked to have a ton of money upfront to work on developing their technology. If they end up succeeding, the investors get their money back along with a success bonus from the government. The government is happy to pay this bonus because the benefits the AI has provided to the world, like low unemployment, far outweigh the small cost they are incurring.
      This creates a kind of virtuous cycle because the government loves what they're getting from the AI, the investors love what they're getting from the government, and the AI companies love that they're receiving upfront money from the investors – so they’ll fight to be eligible to receive it. All my arrows I’ve drawn create new relationships that optimize for all three parties.
      Hopefully this is the solution and I just need to email the President or something like that to get this implemented. But that’s just my idea.
      
      [Speaker: Toby Fenner - 7:38:36PST]
      
      I realize that I probably came across as kind of pessimistic at the beginning, discussing all the negative aspects of AI. So instead of dooming, I want to end on a more optimistic note. 
      We are currently in a unique period where we have only a short window of opportunity to make decisions that will impact the trajectory of AI before it becomes out of our control. It is not only a possibility, but also our responsibility to take action. If we just sit back and do nothing, allowing a Terminator-like scenario to unfold, our grandchildren will wonder why we didn't act. 
      They'll question why we just sat and watched talks like this one without doing anything about it – so you guys can’t really blame me, I’m doing my part.
      These are all large-scale issues, and I know one of the points of this talk is that it's challenging to implement change individually. But as consumers we can choose which companies to support and where to direct our attention. We can decide to vocalize our support for open-source, nonprofit organizations like Hugging Face that are working on ethical AI initiatives.
      On a large scale, we can make a difference. This is an opportunity, and the potential for utility — the utopia I envision — is so great that it's well worth pursuing. So, let’s make that a reality. 
      Thank you.
      
      [Transcript Close - 7:40:18PST]
      `
    }
  ];

  const handleBackClick = () => {
    window.history.back();
  };

  const openWriting = (writing) => {
    setSelectedWriting(writing);
  };

  const closeWriting = () => {
    setSelectedWriting(null);
  };

  return (
    <div className="writing-showcase-wrapper">
      <button className="back-button" onClick={handleBackClick}>Back</button>

      <div className="container">
        <h1 className="heading">Writing</h1>
        <p className="intro">
          Speeches, essays, etc.
        </p>

        <div className="writing-list">
          {writings.map((writing) => (
            <div key={writing.id} className="writing-card" onClick={() => openWriting(writing)}>
              <h2 className="writing-card-title">{writing.title}</h2>
              <p className="writing-card-content">{writing.excerpt}</p>
            </div>
          ))}
        </div>
      </div>

      {selectedWriting && (
        <div className="writing-modal">
          <div className="modal-content">
            <button className="close-button" onClick={closeWriting}>&times;</button>
            <h2>{selectedWriting.title}</h2>
            <div style={{ whiteSpace: 'pre-wrap' }}>{selectedWriting.content}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Transcripts;