import React, { useState, useEffect, useRef } from 'react';
import './App.css'; // Ensure this CSS file includes your highlight animation
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import PrivacyPolicy from './PrivacyPolicy';
import Transcripts from './Transcripts';


function App() {
  const [inputValue, setInputValue] = useState('');
  const [combinedTextArray, setCombinedTextArray] = useState([]);
  const [highlightGroups, setHighlightGroups] = useState({});
  const inputRef = useRef(null); // Create a ref for the input element
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false); // New state to track if an animation is in progress


  // const [placeholderText, setPlaceholderText] = useState('');


  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };
  

  const textToUrlMapping = {
    'GitHub': 'https://github.com/TobyFenn',
    'LinkedIn': 'https://www.linkedin.com/in/tobyfenner/',
    'Email': 'mailto:tfenner@usc.edu',
    'University' : 'https://www.usc.edu/',
    'Southern' : 'https://www.usc.edu/',
    'California' : 'https://www.usc.edu/',
    'Hackathon' : 'https://cruzhacks.com/',
    'interpreter' : 'https://devpost.com/software/holdit-1b2d9n',
    'trojanride.link,' : 'http://trojanride.link/',
    'CS103' : 'https://bytes.usc.edu/cs103/people.html',
    'Viterbi' : 'https://viterbicareers.usc.edu/viterbi-trek-students/',
    'Trek' : 'https://viterbicareers.usc.edu/viterbi-trek-students/',
    'Scope' : 'https://www.scopeusc.com/',
    'Shift' : 'https://www.shiftsc.org/',
    'AMA' : 'https://www.amausc.org/projects-7',
    'Techie' : 'https://www.instagram.com/techie_usc/',
    'Parkside' : 'https://hospitality.usc.edu/residential-dining-menus/',
    'RTX' : 'https://www.rtx.com/',
    'Genies' : 'https://genies.com/',
    'Hood' : 'https://hoodtech.com/',
    'Tech' : 'https://hoodtech.com/',
    'Annenberg' : 'https://www.uscannenbergmedia.com/',
    'Media' : 'https://www.uscannenbergmedia.com/',
    'ORTOP' : 'https://ortop.org/',
    'Sierra' : 'https://sierraolympia.com/',
    'Olympia' : 'https://sierraolympia.com/',
    'FABLE' : 'https://webresearch.eecs.umich.edu/fable/',
    'Fountainhead' : 'https://aynrand.org/novels/the-fountainhead/',
    'Noise' : 'https://www.youtube.com/watch?v=mYIgSq-ZWE0',
    'Black' : 'https://www.nytimes.com/2007/04/22/books/chapters/0422-1st-tale.html',
    'Swan' : 'https://www.nytimes.com/2007/04/22/books/chapters/0422-1st-tale.html',
    'Catch-22' : 'https://www.nytimes.com/books/98/02/15/home/heller-catch.html?_r=1',
    'Brave New World' : 'https://www.plato-philosophy.org/wp-content/uploads/2016/05/BraveNewWorld-1.pdf',
    'Publicly' : 'http://www.jonronson.com/',
    'Shamed' : 'http://www.jonronson.com/',
    'Cliffs,' : 'https://touchstoneclimbing.com/cliffs-of-id/',
    'dumbest' : 'https://www.linkedin.com/in/briant-chen/',
    'Toby' : 'https://www.youtube.com/watch?v=rv4wf7bzfFE/',
    'Fenner!' : 'https://www.youtube.com/watch?v=rv4wf7bzfFE/',

  };

  // Example text groups with simple words for demonstration
  const textGroups = [
    {
      theme: 'hi',
      texts: [
        'Hi, I\'m Toby Fenner!'
      ]
    },
    {
      theme: 'about me',
      texts: [
        'I am a Junior',
        'from Hood River, Oregon',
        'currently studying',
        'Computer Science and Business Administration',
        'at the University of Southern California.',
        'I chose these fields because',
        'I\'m stoked about',
        'product development.',
        'My experience in',
        'research, software engineering, and product management',
        'has taught me how to',
        'build useful tools from scratch.',
      ]
    },
    {
      theme: 'links',
      texts: [
        'You can find me at',
        'GitHub',
        'LinkedIn',
        'Email',
      ]
    },
    {
      theme: 'projects',
      texts: [
        'My team won',
        'the UC Santa Cruz Hackathon',
        'with our',
        'ML-powered ASL interpreter',
        'that we trained ourselves.',
        'I also built trojanride.link,',
        'a web app',
        'that pairs together USC students',
        'to split the cost of a taxi',
        'from the airport',
        'based on their flight arrival time.',
      ]
    },
    {
      theme: 'coursework',
      texts: [
        'I\'ve earned a 3.8 GPA in',
        'Data Structures and Object Oriented Design',
        'Software Engineering',
        'Algorithms and Theory of Computing',
        'Programming in Python',
        'Differential Equations and Linear Algebra',
        'Calculus III',
        'Introduction to Algorithms and the Theory of Computing',
        'Principles of Software Development',
      ]
    },
    {
      theme: 'at usc',
      texts: [
        'On campus, I\'m a',
        'CS103 course producer',
        'Viterbi Trek representative',
        'Deans list student',
        'Scope cohort member',
        'Shift web developer',
        'AMA case competition team member',
        'Techie member',
        'Football season pass holder',
        'Parkside dining hall burrito enjoyer',
      ]
    },
    {
      theme: 'industry',
      texts: [
        'I have had the opportunity to be a',
        'Software Engineer Intern at RTX',
        'Software Engineer Intern at Genies',
        'Software Engineer Intern at Hood Tech',
        'Product Engineer at Annenberg Media',
        'Development intern at ORTOP',
        'Engineering Intern at Sierra Olympia',
      ]
    },
    {
      theme: 'research',
      texts: [
        'FABLE is a system that',
        'automatically finds and redirects',
        'broken web links to their correct new URLs.',
        'I worked on this project in the',
        'USC Networked Systems Lab.'
      ]
    },
    {
      theme: "odd jobs",
      texts: [
        'I\'ve worked as a',
        'ski resort cashier',
        'wine tasting specialist',
        'blueberry picker',
        'historic railroad attendant',
        'sanctuary groundskeeper',
      ]
    },
    {
      theme: 'skills',
      texts: [
        'I have experience in',
        'C++',
        'C#',
        'Java',
        'HTML',
        'CSS',
        'React',
        'Python',
        'SQL',
        'Node.js',
        'Django',
      ]
    },
    {
      theme: 'books',
      texts: [
        'Some of my favorite books are',
        'The Fountainhead',
        'The Signal and the Noise',
        '1984',
        'The Black Swan',
        'Catch-22',
        'Slaughterhouse Five',
        'Brave New World',
        'Sapiens',
        'The Tipping Point',
        'So You’ve Been Publicly Shamed',
      ]
    },
    {
      theme: 'hobbies',
      texts: [
        'In my free time',
        'I enjoy bouldering at',
        'Cliffs of Id,',
        'trying out new film stocks,',
        'producing music,',
        'and losing hackathons.',
      ]
    },
    {
      theme: 'aspirations',
      texts: [
        'In the future,',
        'I hope to',
        'lead product development',
        'for an emerging technology.',
        'I want to be',
        'the dumbest person',
        'in the room.',
        'I hope I can',
        'maintain the curiosity and creativity',
        'that is the driving force',
        'behind all my projects.',
      ]
    },
     {
       theme: '[im feeling lucky]',
       texts: [
         'Click',
          'on',
         'my ',
        'name...']
     },
  ];

  // useEffect(() => {
  //   let groupIndex = 0;
  //   let charIndex = 0;
  //   let isTyping = true;
  //   let timeoutId = null;
  
  //   const updatePlaceholder = () => {
  //     const group = textGroups[groupIndex].theme;
  //     setPlaceholderText(prev => isTyping ? prev + group[charIndex] : prev.slice(0, -1));
  
  //     if (isTyping) {
  //       if (charIndex < group.length - 1) {
  //         charIndex += 1;
  //       } else if (charIndex === group.length - 1) {
  //         isTyping = false; // Start backspacing
  //       } else if (charIndex === 0) {
  //         charIndex = 1;
  //       }
  //     } else {
  //       if (charIndex > 0) {
  //         charIndex -= 1;
  //       } else {
  //         isTyping = true; // Start typing next group
  //         groupIndex = (groupIndex + 1) % textGroups.length;
  //         charIndex = 0; // Reset charIndex for the next group
  //       }
  //     }
  
  //     timeoutId = setTimeout(updatePlaceholder, isTyping ? 600 : 120); // Faster backspacing
  //   };
  
  //   updatePlaceholder();
  
  //   return () => clearTimeout(timeoutId); // Cleanup on unmount
  // }, []);
  
  

  useEffect(() => {
    // Sort textGroups by theme length from shortest to longest
    const sortedTextGroups = [...textGroups].sort((a, b) => a.theme.length - b.theme.length);

    const wovenTexts = weaveTexts(sortedTextGroups);
    setCombinedTextArray(wovenTexts);
    setHighlightGroups(computeHighlightGroups(wovenTexts, sortedTextGroups));
  }, []);
  

  useEffect(() => {
    const focusInput = () => {
      inputRef.current && inputRef.current.focus();
    };

    window.addEventListener('click', focusInput);

    return () => {
      window.removeEventListener('click', focusInput);
    };
  }, []);

  let typingInterval = null; // Declare this outside your component or at the top of your function component

  const handleWordStackClick = (theme) => {
    if (isAnimating) return; // Prevent starting a new animation if one is already in progress
    setIsAnimating(true); // Set the flag to true to indicate animation start

    clearTimeout(typingInterval); // Use clearTimeout for recursive setTimeout
    setInputValue("");

    
    let i = 0;
    let speed = 0; // Start speed
  
    const typeLetter = () => {
      if (i < theme.length) {
        if (i === 0) {
          setInputValue(theme[0]);
        } else {
          setInputValue((prev) => prev + theme[i-1]);
        }
        i++;
        speed += 30; // Increase the delay for an 'ease-out' effect
        typingInterval = setTimeout(typeLetter, speed);
      } else {
        setIsAnimating(false); // Reset the flag once the animation completes

        clearTimeout(typingInterval); // Clear when done
      }
    };
  
    typingInterval = setTimeout(typeLetter, speed);
  };
  
  
  const weaveTexts = (textGroups) => {
    let combinedTextArray = [];
    let longest = textGroups.reduce((max, group) => Math.max(max, group.texts.length), 0);

    for (let i = 0; i < longest; i++) {
      textGroups.forEach(group => {
        if (group.texts[i]) {
          combinedTextArray = combinedTextArray.concat(group.texts[i].split(' '));
        }
      });
    }

    return combinedTextArray;
  };

  function navigateToTranscripts() {
    window.location.href = '/transcripts';
}

function navigateToZdp8() {
  window.location.href = 'https://www.instagram.com/z.dp8/';
}

  const computeHighlightGroups = (combinedTextArray, textGroups) => {
    let highlightGroups = {};

    textGroups.forEach(group => {
      highlightGroups[group.theme] = [];
      group.texts.forEach(phrase => {
        let words = phrase.split(' ');
        let index = combinedTextArray.findIndex((word, idx) =>
          combinedTextArray.slice(idx, idx + words.length).join(' ') === phrase);
        if (index !== -1) {
          for (let i = 0; i < words.length; i++) {
            highlightGroups[group.theme].push(index + i);
          }
        }
      });
    });

    return highlightGroups;
  };

  const handleInputChange = (event) => {
    const newInputValue = event.target.value.toLowerCase();
    setInputValue(newInputValue);
  };
  
  //

  const renderText = () => {
    const totalWords = combinedTextArray.length;
    let correctlyTyped = false;
    let randomHighlights = new Set();

    if (highlightGroups['hi']) {
      highlightGroups['hi'].forEach(index => randomHighlights.add(index));
    }
  
    Object.keys(highlightGroups).forEach((theme) => {
      if (theme.startsWith(inputValue)) {
        correctlyTyped = true;
        const proportion = inputValue.length / theme.length;
        const numWordsToHighlight = Math.ceil(highlightGroups[theme].length * proportion);
  
        // Highlight correct proportion of words
        for (let i = 0; i < numWordsToHighlight; i++) {
          randomHighlights.add(highlightGroups[theme][i]);
        }
      }
    });
  
    // If input is incorrect, highlight random words
    if (!correctlyTyped && inputValue) {
      while (randomHighlights.size < 15) {
        const rIndex = Math.floor(Math.random() * totalWords);
        randomHighlights.add(rIndex);
      }
    }
  
    return combinedTextArray.map((word, index) => {
      let style = {};
      if (randomHighlights.has(index)) {
        const animationName = isDarkMode ? 'highlightAnimationAqua' : 'highlightAnimationYellow';
        style = {
          animation: `${animationName} 1s forwards ${0.1}s`,
        };
      }
      const url = textToUrlMapping[word];
      if (url) {
        return (
          <a key={index} href={url} target="_blank" rel="noopener noreferrer" className="text" style={style}>
            {word}{' '}
          </a>
        );
      }
      return <span key={index} className="text" style={style}>{word} </span>;
    });
  };
  
  return (
    <Router>
      <div className={`app-container ${isDarkMode ? 'dark-mode' : ''}`}>
        <Routes>
          <Route path="/" element={
            <>
              <head>
                <title>Toby Fenner - About Me</title>
  <meta name="description" content="Software Engineer and Creative at USC"/>
<link rel="icon" href="data:image/svg+xml,<svg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%220 0 100 100%22><text y=%22.9em%22 font-size=%2290%22>🎯</text></svg>"/>
    <meta property="og:title" content="Toby Fenner - About Me" />
  <meta property="og:description" content="Software Engineer and Creative at USC" />
  <meta property="og:image" content="URL to the image you want to appear in the preview" />
  <meta property="og:url" content="https://www.tobyfenner.com" />
  <meta property="og:type" content="website"/>
</head>

      <div className="left-half">
      <div className={`word-stack ${isDarkMode ? 'dark-mode' : ''}`}>
  {textGroups.sort((a, b) => a.theme.length - b.theme.length).map((group) => {
    // Skip rendering the 'hi' group
    if (group.theme === 'hi') return null;
    return (
      <div 
        key={group.theme} 
        onClick={() => handleWordStackClick(group.theme)}
        style={{
          color: inputValue === group.theme ? (isDarkMode ? 'aqua' : 'black') : 'lightgray',
        }}>
        {group.theme}
      </div>
    );
  })}
</div>

      <input
          ref={inputRef}
          type="text"
          onChange={handleInputChange}
          value={inputValue}
          placeholder={"click a subject ↑"} // Fallback to "Type query..." if placeholderText is empty
          className={`large-input ${isDarkMode ? 'dark-mode' : ''}`}
          spellCheck="false"
        disabled/>
      </div>
      <div className="right-half">
      <div className={`text-container ${isDarkMode ? 'dark-mode' : ''}`}>
          {renderText()}
        </div>
      </div>
      {/* <div href="#" className="page-title" onClick={(e) => { e.preventDefault(); toggleDarkMode(); }}>TOBY FENNER</div> Add this line */}
      {/* <a href="#" className="underline-link" onClick={(e) => { e.preventDefault(); toggleDarkMode(); }}>click here</a> */}
      <button className={`peace-button ${isDarkMode ? 'dark-mode' : ''}`} onClick={() => setIsModalVisible(true)}>
      ✌️
    </button>
    {/* <button className={`light-button ${isDarkMode ? 'dark-mode' : ''}`} onClick={(e) => { e.preventDefault(); toggleDarkMode(); }}>
    💡
    </button> */}
    
    <button className={`transcripts-button ${isDarkMode ? 'dark-mode' : ''}`} onClick={(e) => { e.preventDefault(); navigateToTranscripts();}}>
    📕
    </button>

    <button className={`photos-button ${isDarkMode ? 'dark-mode' : ''}`} onClick={(e) => { e.preventDefault(); navigateToZdp8();}}>
    📷
    </button>


            <Link to="/privacypolicy" className={`legal-button ${isDarkMode ? 'dark-mode' : ''}`}>
              📜
            </Link>


            {isModalVisible && (
        <div className={`modal ${isDarkMode ? 'dark-mode' : ''}`}>
      <div className={`modal-content ${isDarkMode ? 'dark-mode' : ''}`}>
      <span className="close" onClick={() => setIsModalVisible(false)}>&times;</span>
      <img src={`${process.env.PUBLIC_URL}/headshot.jpeg`} alt="Modal" className="modal-image" />
      <h2 className={`modal-title ${isDarkMode ? 'dark-mode' : ''}`}>Thanks for checking out my website!</h2>
      <p className={`modal-text ${isDarkMode ? 'dark-mode' : ''}`}>
      My site's design is inspired by the <a href="https://en.wikipedia.org/wiki/Cardan_grille" className="underline-link" target="_blank" rel="noopener noreferrer">Cardan grille</a>.
      </p>
      <p className={`modal-text ${isDarkMode ? 'dark-mode' : ''}`}>
        Click on a menu item to reveal its hidden message.
      </p>
      {/* <p className={`modal-text ${isDarkMode ? 'dark-mode' : ''}`}>
        Alternatively, you may type in your subject manually.
      </p> */}
      <p className={`modal-text ${isDarkMode ? 'dark-mode' : ''}`}>
        To learn more about a subject, just click on its hyperlink.
      </p>
      <p className={`modal-text ${isDarkMode ? 'dark-mode' : ''}`}>
FTFO!
      </p>
    </div>
  </div>
            )}
            </>
          } />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/transcripts" element={<Transcripts />} />
        </Routes>
      </div>
    </Router>
  );
}


export default App;
